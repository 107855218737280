import React, { useEffect, useState } from "react";
import '../styles/header/popupRouter.scss'
import { TbCrane } from "react-icons/tb";
import { FaBasketShopping, FaIndustry  } from "react-icons/fa6";
import { GiCargoCrane } from "react-icons/gi";
import { CiSearch } from "react-icons/ci";
import { IoClose } from "react-icons/io5";
import LocalizedLink from "./localizedLink";
import Image from "./image";
import { RichText } from "prismic-reactjs";
import { HiArrowTopRightOnSquare } from "react-icons/hi2";
import {IoIosArrowForward} from 'react-icons/io'
import { MdOutlineKeyboardDoubleArrowRight, MdOutlineKeyboardArrowRight } from "react-icons/md";


export const PopupRouter = ({headerDataLang, locale, useCasesDataLang}) => {
    
    const [isOpen, setIsOpen] = useState(true)

    const [searchBarValue, setSearchBarValue] = useState('')
    
    let popupDataVertical = []
    let popupDataUseCase = []

    useCasesDataLang = useCasesDataLang[0].node.use_cases

    const headerDataLangBody = headerDataLang.body
    
    // Get only menu with subgroup
    const popupData = headerDataLangBody.filter(
        item => item.type === "menu_group_with_subgroup"
    )
        
    // Set object popupPrimary with id / master_title / name
    const popupPrimary = {
        id: popupData[1].primary.menu_group_with_sub_group_id[0].text,
        master_title: popupData[1].primary.menu_group_with_sub_group_master_title[0].text,
        name: popupData[1].primary.menu_group_with_sub_group_name[0].text,
    }

    // Get Sector's title and sectors link / Usecase's title
    popupData.map((menu, index) => {
        if(index === 1) {
            popupDataVertical.push(menu.fields)
        }

        if(index === 4) {
            popupDataUseCase.push(menu.fields)
        }
    })

    let popupDataVerticalObject = []
    
    popupDataVertical.map((menu) => {
        menu.map(menu => {
            popupDataVerticalObject.push({ 
                name : menu?.menu_group_with_sub_group_element_name[0]?.text,
                link : menu?.menu_group_with_sub_group_element_link?._meta?.uid,
                filter: menu?.menu_groupe_with_sub_group_element_filter
            })
        })
    })

    const iconArray = () => [
        <FaBasketShopping size={30} style={{color: "#F9C846"}} />, 
        <TbCrane size={30} style={{color: "#808c97"}} />, 
        <GiCargoCrane size={30} style={{color: "#5769A4"}} />,
        <FaIndustry size={30} style={{color: "#f96400"}} />,
        <img src="/images/monstock-icone-final-economie-circulaire.svg" style={{width: "30px", height: "30px", color: "#7CC242"}}/>
    ]

    const [filter, setFilter] = useState({index: null, filter: null})

    const filterOnClick = (index) => {
        // To have multiple type filter
        // Same useCasesDataLang but with [''] as use_case_type
        useCasesDataLang = useCasesDataLang.map(usecase => {
            // Delete space
            const usecaseType = usecase.use_case_type
            
            if (usecaseType !== null) {
                return {...usecase, use_case_type: usecaseType.split(",")}
            } else {
                return {...usecase}
            }
        });

        
    
        if (filter.index === null) {
            setFilter({index, filter: popupDataVerticalObject[index].filter})
        } else if (filter.index === index) {
            setFilter({index : null, filter: null}) 
        } else {
            setFilter({index, filter: popupDataVerticalObject[index].filter})
        }

    }
    
    return (
        isOpen && (
            <div className="popup_container">
                <p>{filter && JSON.stringify(filter)}</p>
                <div className="popup_background" onClick={() => {setIsOpen(false)}}></div>
                <div className="popup_card">
                    <nav>
                        <IoClose size={30} className="icon-close" onClick={() => {setIsOpen(false)}} />
                    </nav>
                    <div className="popup_content">
                        <section className="popup_content__sectors">
                            <h1>{popupPrimary.name}</h1>
                            <ul>
                                {popupDataVerticalObject.map((item, index) => (
                                    <li css={filter.index === index && {outline: '2px solid #fa6400 !important'}} style={item.link !== null || item.link !== undefined ? {cursor: "not-allowed !important"} : null} key={index} onClick={() => {filterOnClick(index)}}>
                                        {iconArray()[index]}
                                        <span>{item.name}</span>
                                        {filter.index === index && (
                                            <div className="filter-link__icon">
                                                <LocalizedLink to={item.link}>
                                                    <MdOutlineKeyboardDoubleArrowRight size={30} />
                                                </LocalizedLink>
                                            </div>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </section>
                        <section className="popup_content__usescases">
                            <h1>{RichText.asText(popupDataUseCase[0][0].menu_group_with_sub_group_element_name)}</h1>
                            <div className="popup_content__usecases-searchbar-container">
                                <CiSearch size={20} />
                                <input type="text" value={searchBarValue} onChange={(e) => setSearchBarValue(e.target.value)} />
                            </div>
                            <ul>
                                {useCasesDataLang
                                    .filter((usecase) => {
                                        // Si searchBarValue est vide, ne pas filtrer par recherche
                                        if (searchBarValue.trim() === "") {
                                            return filter.filter !== null
                                                ? usecase.use_case_type !== null &&
                                                usecase.use_case_type.includes(filter.filter)
                                                : true; // Aucun filtre appliqué
                                        }

                                        // Si searchBarValue n'est pas vide, filtrer par recherche
                                        const title = RichText.asText(usecase.use_case_title).toLowerCase();
                                        const searchValue = searchBarValue.toLowerCase();
                                        const matchesSearch = title.startsWith(searchValue);

                                        // Appliquer le filtre principal et le filtre par recherche
                                        return filter.filter !== null
                                            ? usecase.use_case_type !== null &&
                                            usecase.use_case_type.includes(filter.filter) &&
                                            matchesSearch
                                            : matchesSearch;
                                    })
                                    .map((usecase, index) => (
                                        <li key={index}>
                                            <LocalizedLink to={`usecases/${usecase.use_case_id}`}>
                                                <div className="usecase__image">
                                                    {usecase.use_case_image && (
                                                        <Image
                                                            sharp={usecase.use_case_imageSharp}
                                                            image={usecase.use_case_image}
                                                        />
                                                    )}
                                                </div>
                                                <section>
                                                    <h2>{RichText.asText(usecase.use_case_title)}</h2>
                                                </section>
                                            </LocalizedLink>
                                        </li>
                                    ))}
                            </ul>
                        </section>      
                    </div>
                </div>
            </div>
        )
    )
}